/* FAQPreview.css */
.faq-preview-section {
    padding: 20px;
    background-color: #f9fbfd;
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  
  .faq-preview-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-preview-items {
    display: flex;
    /* grid-template-columns: 2fr;  */
    gap: 20px; /* Space between the items */
    flex-wrap: wrap;
  justify-content: space-around;
  }
  
  .faq-preview-item {
    flex: 0 1 calc(50% - 20px);
    background-color: #e9f1f9;
    border-radius: 20px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
/*   
  .faq-preview-item:hover {
    background-color: #d4e4f1;
  } */
  
  .faq-preview-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #384e68;
  }
  
  .faq-preview-toggle {
    font-size: 30px;
    color: #218721;
    font-weight: 500;
  }
  
  .faq-preview-answer {
    margin-top: 10px;
    font-size: 16px;
    color: #384e68;
  }
  
  .faq-more-questions {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
  }
  
  .more-questions-button {
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer;
    color: #384e68;
    text-transform: uppercase;
    transition: background-color 0.3s;
  }
  
  .more-questions-button:hover {
    background-image: none;
    background-color: white; 
    border: 1px solid #08f784;
    color: #384e68;
  }
.faq-preview-answer a {
    color: #32CD32;
    font-weight: 600;
}
.faq-preview-answer a:hover {
    color:#384e68;
}