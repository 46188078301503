.conditions-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.conditions-content {
  text-align: center;
}

.conditions-intro {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  text-align: left;
}

.conditions-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  gap: 1rem;
}

.conditions-filters select,
.conditions-filters input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.conditions-filters input {
  min-width: 300px;
}

.sort-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conditions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.condition-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s;
}

.condition-card:hover {
  transform: translateY(-5px);
}

.condition-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.condition-card h3 {
  padding: 1rem;
  margin: 0;
}

/* Condition Detail Styles */
.condition-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.condition-title {
  text-align: center;
  margin-bottom: 2rem;
}

.condition-content {
  margin-bottom: 2rem;
}

.cta-container {
  text-align: center;
  margin: 2rem 0;
}

.condition-tags {
  margin: 2rem 0;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  background: #f0f0f0;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.condition-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  border-top: 1px solid #eee;
  padding-top: 1rem;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: inherit;
}

.nav-link.prev {
  margin-right: auto;
}

.nav-link.next {
  margin-left: auto;
}

.arrow {
  font-size: 1.5rem;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.blog-card {
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 1.5rem;
}

.condition-excerpt {
  margin: 1rem 0;
  color: #666;
  line-height: 1.6;
}

.read-more {
  display: inline-block;
  color: #4CAF50;
  text-decoration: none;
  font-weight: 600;
  margin-top: 1rem;
}

/* Detail page styles */
.condition-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.condition-title {
  text-align: center;
  margin-bottom: 2rem;
}

.cta-container {
  text-align: center;
  margin: 3rem 0;
}




.navigation-links {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: inherit;
  max-width: 45%;
}

.nav-link.prev {
  margin-right: auto;
}

.nav-link.next {
  margin-left: auto;
  text-align: right;
}

.arrow {
  font-size: 1.5rem;
  color: #4CAF50;
}

.nav-title {
  font-weight: 500;
}


