.blog-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
  
  .blog-main {
    flex: 3;
    margin-right: 20px;
  }
  
  .blog-posts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .blog-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: calc(33.333% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 15px;
  }
  
  .blog-content h2 {
    font-size: 1.25rem;
    margin: 0 0 10px;
  }
  
  .blog-content p {
    font-size: 1rem;
    color: #555;
  }
  
  .read-more {
    color: #008000;
    text-decoration: none;
    font-weight: bold;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  
  .blog-sidebar {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
  }
  
  .sidebar-logo {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .book-appointment {
    display: block;
    text-align: center;
    padding: 10px 20px;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 20px;
  }
  
  .blog-sidebar h3 {
    margin-bottom: 10px;
  }
  
  .blog-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .blog-sidebar ul li {
    margin-bottom: 10px;
  }
  
  .blog-sidebar ul li a {
    color: #008000;
    text-decoration: none;
    font-weight: bold;
  }
  
  .blog-sidebar ul li a:hover {
    text-decoration: underline;
  }
  
  .full-width {
    width: 100% !important;
    max-width: 1200px;
    margin: 0 auto;
  }
  .strain-detail {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .strain-featured-image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    margin: 2rem 0;
  }
  
  .strain-details {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .strain-details > div {
    background: #f5f5f5;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .strain-details h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .strain-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .strain-tabs {
    display: flex;
    margin: 2rem 0 1rem;
    border-bottom: 2px solid #eee;
    width: 100%;
  }
  
  .tab-button {
    flex: 1;
    padding: 0.75rem 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    color: #666;
    position: relative;
    white-space: nowrap;
  }
  
  .tab-button.active {
    color: #000;
    font-weight: 600;
  }
  
  .tab-button.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #4CAF50;
  }
  
  .tab-content {
    padding-top: 1rem;
  }
  
  .strain-overview,
  .effects-section,
  .growing-section {
    display: grid;
    gap: 2rem;
  }
  
  .cannabinoids-section,
  .strain-types-section,
  .terpenes-section,
  .flavonoids-section,
  .effects-section > div,
  .growing-section > div {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .strain-featured-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  h3 {
    margin-bottom: 1rem;
    /* color: #333; */
  }
  
  .strain-overview-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .growing-details-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .strain-content {
    margin: 2rem 0;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .cannabinoids-section,
  .strain-types-section,
  .terpenes-section,
  .flavonoids-section,
  .height-section,
  .humidity-temp-section,
  .flowering-section {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .effects-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .effects-grid h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
  }
  
  .strain-detail-container h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
  }
  .effects-list {
    list-style-type: disc;
    padding-left: 2rem;
    margin: 1rem 0;
  }
  
  .effects-list li {
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .positive-effects,
  .adverse-effects {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .positive-effects h2,
  .adverse-effects h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
  }
  
  .strain-header {
    display: flex;
    gap: 2rem;
    margin: 2rem 0;
    align-items: flex-start;
  }
  
  .strain-image-container {
    flex: 0 0 25%;
    min-width: 25%;
  }
  
  .strain-featured-image {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .strain-intro-content {
    flex: 0 0 75%;
  }
  
  @media (max-width: 768px) {
    .strain-header {
      flex-direction: column;
    }
    
    .strain-image-container,
    .strain-intro-content {
      flex: 0 0 100%;
    }
  }