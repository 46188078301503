/* HowItWorks.css */
.how-it-works-section {
    text-align: center;
    padding: 40px;
    background-color: #f0fbff; /* Light background */
    /* border-radius: 12px; */
    margin: auto; /* Center the section horizontally */
    /* max-width: 1200px; */
    border-radius: 20px;
  }
  
  .how-it-works-title {
    font-size: 28px;
    color: #218721;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .how-it-works-section a {
    color: #32CD32;
    font-weight: 600;
  }
  .how-it-works-section a:hover {
    color:#384e68;
  }
  .how-it-works-subtitle {
    font-size: 42px;
    color: #384e68;
    margin-bottom: 40px;
  }
  
  .how-it-works-steps {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px; /* Space between step items */
  }
  
  .how-it-works-step {
    flex: 1 1 200px; /* Flex grow to fit, min width 200px */
    max-width: 250px; /* Max width for each step */
    text-align: center;
  }
  
  .how-it-works-image {
    width: 120px; /* Adjust size */
    height: auto;
    margin-bottom: 20px;
  }
  
  .how-it-works-description {
    font-size: 16px;
    color: #384e68;
    margin-top: 10px;
  }
  
  .ctabutton {
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer; /* Change cursor on hover */
    color: #384e68;
    text-transform: uppercase;
    margin-top: 20px; /* Space above the button */
  }
  
  .ctabutton:hover {
    background-image: none;
    background-color: white; /* Change background color on hover */
    border: 1px solid #08f784;
    color: #384e68;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .how-it-works-steps {
      flex-direction: column; /* Stack steps vertically on small screens */
    }
  
    .how-it-works-step {
      max-width: 100%; /* Full width for each step on small screens */
    }
  }