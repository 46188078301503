/* FindDoctorSection.css */
.find-doctor-section {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    min-height: 100vh;
    /* overflow: hidden; */
    overflow: visible;
    margin: 0; /* Reset margin */
    padding: 0; /* Reset padding */
  }
  
  .left-column {
    flex: 1;
    position: relative;
    width: 50%;
    height: auto;
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }
  
  .headline {
    color: #fff;
    font-size: 36px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    max-width: 90%;
    position: sticky;
    top: 100px;
  }
  
  .right-column {
    flex: 1.2;
    padding: 40px 20px;
    background-color: #f9f9f9;
    overflow-y: auto;
    width: calc(50% - 40px);
  }
  
  .content {
    max-width: 100%;
  }
  
  .scrollable-content p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .scrollable-content .text-link {
    color: #32CD32;
    font-weight: 600;
  }
  
  .scrollable-content .text-link:hover {
    color: #384e68;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  .cta-button {
    display: inline-block;
    background-image: linear-gradient(to right, #08f784, #4ac9f3);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer;
    color: #384e68;
    text-transform: uppercase;
    margin: 20px 0;
    text-align: center;
    text-decoration: none;
  }
  
  .cta-button:hover {
    background-image: none;
    background-color: white;
    border: 1px solid #08f784;
    color: #384e68;
  }