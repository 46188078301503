.contact-us {
  max-width: 80%;
  margin: auto;
  margin-top: 40px;
}
  
.contact-us h1 {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 32px;
  font-size: 48px;
  text-transform: uppercase;
  
  
}

.location-section {
  background-color: #f0fbff;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
}

/* OfficeLocations.css */
.office-locations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.office {
  text-align: center;
  flex: 1; /* Make the columns flexible */
  max-width: 230px; /* Limit the maximum width to ensure proper spacing */
}

.office-city {
  font-size: 24px;
}

.office-city::after {
  content: '';
  display: block;
  width: 50px;
  height: 1px;
  background-color: #32CD32; /* Green underline */
  margin: 5px auto 15px auto;
}

.office-address,
.office-contact {
  font-size: 16px;
  color: #384e68;
  margin-bottom: 10px;
}

.office-contact {
  text-align: center;
}

.office-contact a, .email-contact a {
  color: #32CD32;
  font-weight: 600;
}

.office-contact a:hover, .email-contact a:hover {
  color: #384e68;
}
.email-contact {
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .office {
    max-width: 100%; /* Allow full width for smaller screens */
  }
  .contact-us {
    max-width: 100%;
    margin: auto;
    padding: 20px;
    margin-top: 20px;
  }
}



/* ContactForm.css */
.contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group input,
.form-group textarea {
  width: 48%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.button-wrapper {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px; /* Add margin if needed */
}
.submit-button {
  display: block;
  padding: 10px 20px;
  background-image: linear-gradient(to right, #08f784, #4ac9f3);
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid transparent;
  color: #384e68;
  text-transform: uppercase;
}

.submit-button:hover {
  background-image: none;
    background-color: white; 
    border: 1px solid #08f784;
    color: #384e68;
}
.contact-us #dotsone{
  left: 5%;
  top: 30%;
}
.contact-us  #dotstwo {
  left: 65%;
  top: 90%;
}