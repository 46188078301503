.how-it-works {
    max-width: 80%;
    margin: auto;
    padding: 20px;
  }
  
.how-it-works h1 {
    text-align: left;
    margin-bottom: 10px;
    font-size: 48px;
    text-transform: uppercase;
}
.how-it-works h2 {
    text-align: left;
}

.how-it-works .section {
    height: 420px;
}