/* CTA.css */
.cta-section {
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 40px auto; /* Center the section horizontally */
  }
  
  .cta-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .cta-text {
    max-width: 60%;
  }
  
  .cta-title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .cta-description {
    font-size: 16px;
    color: #384e68;
    margin-bottom: 20px;
  }
  
  .cta-description strong {
    color: #218721;
  }
  
  .cta-section .ctabutton {
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer; 
    color: #384e68;
    text-transform: uppercase;
  }
  .cta-section .ctabutton:hover {
    background-image: none;
    background-color: white; 
    border: 1px solid #08f784;
    color: #384e68;
  }

  .cta-image img {
    width: 300px;
    height: auto;
    display: block;
  }
  


  .cta-dots {
    position:absolute;
    top: -50px; /* Adjust as needed */
    left: -80px; /* Adjust as needed */
    width: 350px; /* Adjust width */
    height: 350px; /* Adjust height */
    display: grid;
    grid-template-columns: repeat(10, 10px); /* Create 10 columns with 10px each */
    grid-template-rows: repeat(10, 10px); /* Create 10 rows with 10px each */
    gap: 10px; /* Space between dots */
    overflow: hidden; /* Hide overflow to create the corner effect */
    z-index: -1;
  }
  
  .cta-dots::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #0694d0 3px, transparent 2px);
    background-size: 20px 20px; 
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    /* transform: rotate(20deg); */
  }
  
  /* Media Query for responsiveness */
  @media (max-width: 768px) {
    .cta-content {
      flex-direction: column;
      text-align: center;
    }
  
    .cta-text {
      max-width: 100%;
    }
  
    .cta-image img {
      width: 200px;
      margin-top: 20px;
    }
  
  }