/* BenefitsSection.css */
.benefits-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    width: 100%;
  }
  
  .benefits-heading {
    font-size: 32px;
    text-align: center;
  }
  
  .benefits-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .content-column {
    flex: 2; /* Take more space for the content */
    padding-right: 20px; /* Space between text and sidebar */
  }
  
  .sidebar-column {
    flex: 1.3;
    background-color: #131e24;
    border-radius: 20px;
    padding: 20px;
    color: #fff;
    text-align: left;
  }
  
  .benefit-item {
    border-bottom: 1px solid #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .benefit-item.active {
    border-bottom: none;
  }
  
  .benefit-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    width: 100%;
    color: #fff;
  }
  
  .toggle-icon {
    margin-left: auto;
    font-size: 24px;
  }
  
  .benefit-content {
    font-size: 16px;
    color: #ddd;
  }
  
  .benefit-item.active .benefit-title {
    align-items: flex-start;
    width: 100%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .benefits-container {
      flex-direction: column;
    }
  
    .content-column,
    .sidebar-column {
      flex: none;
      width: 100%;
      padding: 0;
    }
  
    .sidebar-column {
      /* margin-top: 20px; */
    }
  }