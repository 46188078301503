.how-it-works-section, .reviews-section, .benefits-cta-section, .why-choose-us-section, .get-approved, .membership-section, .benefits-section {
    max-width: 80%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
}
/* .how-to-qualify {
    max-width: 80%;
    margin: auto;
    padding: 20px;
} */
.how-to-qualify h1 {
    /* text-align: left; */
    margin-bottom: 10px;
    font-size: 48px;
    text-transform: uppercase;
}
.how-to-qualify h2 {
    text-align: left;
}

.how-to-qualify .reviews-heading {
    border-bottom: 1px solid #3fdc8f;
    padding-bottom: 15px;
  }

.how-to-qualify .half-ring {
left: 93%;
filter: none;
opacity: 1;
}
.get-approved a {
    color: #32CD32;
    font-weight: 600;
}
.get-approved a:hover {
    color:#384e68;
}
.get-approved .button-wrapper {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    margin-top: 20px; /* Add margin if needed */
}

.get-approved .cta-button {
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer; /* Change cursor on hover */
    color: #384e68;
    text-transform: uppercase;
}

.get-approved .cta-button:hover {
    background-image: none;
    background-color: white; 
    border: 1px solid #08f784;
    color: #384e68;
}
.full-width-section {
    max-width: 100% !important;
}