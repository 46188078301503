/* BenefitsCTA.css */
.benefits-cta-section {
    text-align: center;
    padding: 40px;
    border-radius: 20px;
    margin: 40px auto;
    margin-top: 0;
    background-color: #f0fbff;
    /* max-width: 1200px; */
  }
  
  .benefits-heading {
    font-size: 42px;
    margin-bottom: 40px;
  }
  
  .benefits-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .benefit-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    flex: 1;
    margin: 10px;
  }
  
  .benefit-icon {
    width: 60px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .benefit-title {
    font-size: 24px;
    color: #32CD32;
    margin-bottom: 20px;
  }
  
  .benefit-description {
    font-size: 16px;
    color: #384e68;
  }
  
  .cta-button {
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer; /* Change cursor on hover */
    color: #384e68;
    text-transform: uppercase;
  }
  
  .cta-button:hover {
    background-image: none;
    background-color: white; 
    border: 1px solid #08f784;
    color: #384e68;
  }