.stats-section {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
    padding: 40px;
    background-color: #384e68;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    width: 100%;
    position: relative;
    box-shadow: 0 5px 2px -2px grey;
}

/* Stat item styling */
.stat-item {
    display: flex;
    flex-direction: column; /* Align the text under the number */
    align-items: center; /* Center the number and text horizontally */
    position: relative;
    padding: 0 20px;
    margin: 20px;
}

/* Stat number styling */
.stat-number {
    font-size: 40px;
    color: #FFFFFF;
    margin-bottom: 10px; /* Space between number and description */
}

/* Stat description styling */
.stat-description {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    text-align: center; /* Center align the description text */
}

/* Vertical separator styling */
.stat-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: -90%; /* Adjust to place separator correctly */
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 1px;
    background-color: #fff;
}

/* Half-ring SVG positioning */
.half-ring-wrapper {
    position: absolute;
    top: -30%; /* Adjust based on desired position */
    right: 20%; /* Adjust based on desired position */
}

.half-ring {
    width: 150px; /* Adjust size as needed */
    height: auto;
}