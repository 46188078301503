/* WhyChooseUs.css */
.why-choose-us-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    background-color: #f9fbfd; /* Light background */
    border-radius: 20px;
    margin: 40px auto; /* Center the section horizontally */
    max-width: 1200px;
    flex-wrap: wrap;
  }
  
  .why-choose-us-content {
    flex: 1;
    margin-right: 20px;
  }
  
  .why-choose-us-title {
    font-size: 42px;
  }
  
  .why-choose-us-description {
    font-size: 16px;
    color: #384e68;
    margin-top: 20px;
  }
  
  .cta-button {
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer; /* Change cursor on hover */
    color: #384e68;
    text-transform: uppercase;
    margin-top: 20px; /* Space above the button */
  }
  
  .cta-button:hover {
    background-image: none;
    background-color: white; /* Change background color on hover */
    border: 1px solid #08f784;
    color: #384e68;
  }
  
  .why-choose-us-features {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .feature-card {
    flex: 1 1 45%; /* Flex basis for responsive resizing */
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .feature-card:hover {
    transform: translateY(-10px); /* Slight lift on hover */
  }
  
  .feature-image {
    width: 80px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .feature-title {
    font-size: 18px;
    color: #384e68;
    margin-bottom: 10px;
  }
  
  .feature-description {
    font-size: 14px;
    color: #384e68;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .why-choose-us-section {
      flex-direction: column;
      align-items: center;
    }
  
    .why-choose-us-content {
      margin-right: 0;
      margin-bottom: 20px; /* Space between content and features */
    }
  
    .why-choose-us-features {
      flex-direction: column;
    }
  
    .feature-card {
      width: 100%;
    }
  }