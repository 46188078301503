.about-us {
    padding: 20px;
    max-width: 80%;
    margin: auto;
  }
  
  .about-us h1 {
    text-align: left;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 48px;
  }
  
  .about-us h2 {
    text-align: left;
  }
  
  .why-choose-us {
    margin: 40px 0;
    padding-right: 10%;
  }
  
  .about-us .reviews-heading {
    border-bottom: 1px solid #3fdc8f;
    padding-bottom: 15px;
  }

  .about-us .half-ring {
    left: 93%;
    filter: none;
    opacity: 1;
  }