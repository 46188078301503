/* TrustpilotReviews.css */
.reviews-section {
    text-align: center;
    padding: 40px;
    background-color: #f0fbff; /* Light background */
    position: relative;
    overflow: hidden;
    border-radius: 20px;
  }
  
  .reviews-heading {
    font-size: 42px;
    color: #384e68;
    margin-bottom: 40px;
  }
  
  .text-highlight-green {
    color: #32CD32; /* Green highlight */
  }
  
  .half-ring {
    position: absolute;
    top: 0;
    left: 20%;
    width: 175px;
    height: auto;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0.8;
  }
  
  .slick-slider {
    padding: 20px 0;
  }
  
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  
  .review-card {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Evenly distribute space */
    padding: 40px;
    background-color: #f0fbff;
    border-radius: 20px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .review-card:hover {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .review-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .review-initials {
    background-color: #f8d7da;
    color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 10px;
  }
  
  .reviewer-info {
    font-size: 14px;
    color: #384e68;
  }
  
  .reviewer-name {
    font-weight: bold;
  }
  
  .review-details {
    color: #6c757d;
    margin-top: 5px;
  }
  
  .review-rating {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .star {
    color: #ffc107; /* Gold color for stars */
  }
  
  .star.inactive {
    color: #e4e5e9; /* Grey color for inactive stars */
  }
  
  .verified {
    margin-left: 10px;
    color: #6c757d;
    font-size: 12px;
  }
  
  .review-date {
    margin-left: 10px;
    color: #6c757d;
    font-size: 12px;
  }
  
  .review-title {
    font-size: 16px;
    color: #384e68;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .review-content {
    font-size: 14px;
    color: #6c757d;
    flex-grow: 1; /* Ensure content stretches to fill available space */
  }
  
  .review-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Custom slick styles */
  .slick-dots li button:before {
    color: #32CD32;
  }
  
  .slick-prev, .slick-next {
    color: #384e68;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .review-card {
      width: 90%;
    }
  }