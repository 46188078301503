/* Membership.css */
.membership-section {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between text and logos */
    padding: 40px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 40px auto; /* Center the section horizontally */
  }
  
  .membership-text {
    text-align: left; /* Left align the text */
    max-width: 40%; /* Limit the width to avoid overlapping */
  }
  
  .membership-title,
  .membership-subtitle {
    font-size: 42px;
    margin: 0;
  }
  
  .membership-title {
    color: #218721; /* Green color for "Proud" */
  }
  
  .membership-subtitle {
    color: #384e68; /* Darker color for "Member Of" */
  }
  
  .membership-logos {
    display: flex;
    gap: 70px; /* Space between logos */
    justify-content: flex-end; /* Align logos to the right */
    flex-grow: 1; /* Allow logos to take remaining space */
    align-items: center;
  }
  
  .membership-logo {
    width: 120px;
    height: auto;
    filter: grayscale(100%); /* Black & white by default */
    transition: filter 0.3s, transform 0.3s; /* Smooth transition */
  }
  
  .membership-logo:hover {
    filter: grayscale(0%); /* Color on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .membership-section {
      flex-direction: column; /* Stack vertically on small screens */
      align-items: flex-start; /* Align items to the start */
    }
  
    .membership-text {
      max-width: 100%; /* Take full width on small screens */
      margin-bottom: 20px; /* Space between text and logos */
    }
  
    .membership-logos {
      justify-content: flex-start; /* Align logos to the left */
      gap: 20px; /* Reduce space between logos */
    }
  
    .membership-logo {
      width: 100px; /* Smaller logos on smaller screens */
    }
  }