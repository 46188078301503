/* @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap'); */

/* THREE FONTS */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital@0;1&display=swap');
/* FOUR FONTS */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital@0;1&display=swap');
/* Custom */

/* TEST GREEN: #218721 */
/* TEST BLUE: #037DA5 */
/* Current GREEN: #32CD32 */


/* FONTS V1 (just poppins) */
body {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #131e24;
}
a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  /* font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: normal; */
  color: #131e24;
}
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #218721;
  font-size: 30px;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
  color: #384e68;
}
.dropbtn {
  font-size: 16px; 
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  /* font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: normal; */
  color: #131e24; 
}
.ctabtn {
  font-size: 16px; 
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none !important; 
  color: #131e24 !important; 
}
.ctabtn:hover {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none !important; 
  color: #131e24 !important; 
}
.footer, .footer a {
  color: white;
}
.footer-terms a {
  font-size: 0.9em;
}
.footer a:hover {
  text-decoration: underline;
  color: #08f784;
}
.location h3 {
  margin-bottom: 10px;
}
.location p {
  font-size: 0.9em;
  line-height: 1.6;
}
.footer-nav a {
  font-size: 16px;
}
.footer-nav a:hover {
  text-decoration: underline;
  color: #08f784;
}
.copy-right {
  font-size: 0.8em;
}
.social-links a {
  transition: color 0.3s ease;
  font-size: 16px;
}
.hero-section select, .hero-section button {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #131e24; 
} 
button {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 16px;
}
.text-highlight-green {
  /* color: #32CD32; */
  /* color: #218721; */
  color: #218721 !important;
  /* TEST GREEN: #218721 */
/* TEST BLUE: #037DA5 */
}

/* END FONTS */

* {
  box-sizing: border-box;
}

.navmenu {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1000;
  top: 0;
  position: sticky; 
  box-shadow: 0 2.5px 2px -2px grey;
}

.logo img {
  height: 50px; /* Adjust based on your logo's size */
  width: auto;
  padding-left: 20%;
}

#benefits {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
@media (max-width: 768px) {
  #benefits {
    flex-direction: column;
  }
}


/* NAV / HEADER / FOOTER*/

nav a {
  margin-right: 15px; 
  position: relative; 
}
nav a.active {
  color: blue; /* Change this to the color you want for the active link */
}
nav a::after {
  content: '';
  position: absolute;
  bottom: -15px; 
  left: 50%;
  transform: translateX(-50%);
  width: 10px; 
  height: 10px;
  background-color: #49d3ff; 
  border-radius: 50%;
  opacity: 0; 
  transition: opacity 0.3s ease; 
}
nav a:hover::after {
  opacity: 1; 
}

.dropdown-content a:hover::after {
  display: none;
}
.dropdown-content a:hover {
  text-decoration: underline; 
  text-decoration-thickness: 2px;
  text-decoration-color: #49d3ff;
}


.ctabtn::after {
  display: none;
}

.dropdown {
  padding: 0;
  display: inline-block;
   /* width: 100%; */
}

.dropbtn {
  padding: 8px; 
  background-color: white;
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor on hover */
  margin-right: 8px;
  /* width: 100%; */
  box-sizing: border-box;
}

.ctabtn {
  background-image: linear-gradient(to right, #08f784, #4ac9f3); 
  padding: 10px; 
  border: none;
  border-radius: 20px;
  border: 1px solid transparent;
  cursor: pointer; /* Change cursor on hover */
}
.ctabtn:hover {
  background-image: none;
  background-color: white; /* Change background color on hover */
  border: 1px solid #08f784;
}

.dropdown-content {
  background-color: white;
  border: 1px solid #384e68;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1); 
  /* margin-top: 5px; */
  display: none;
  position: absolute;
  min-width: 160px; /* Set dropdown width */
  z-index: 1; /* Make sure it's on top */
  overflow: hidden;
}

.dropdown-content a {
  padding: 10px 20px; /* Add some padding */
  color: #384e68;
  text-decoration: none;
  display: block; /* Display as block */
  /* width: 160px; */
  cursor: pointer;
  /* border-radius: 20px; */
}

.dropdown-content a:hover {
  width: 100%;
  background-color: #f0f0f0;
  text-decoration: none;
} 

.dropdown:hover .dropdown-content {
  display: block; 
}

.dropdown:hover .dropbtn {
  background-image: linear-gradient(to right, #08f784, #4ac9f3);
  border-radius: 20px;
}

.va-loc-section {
  text-align: center;
  h3 {
    color: #008000;
  }
  a {
    color: #000 !important;
  }
}


/* FOOTER */

.footer-top {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #08f784; /* Top border for the bottom footer */
  padding-bottom: 20px;
  margin-bottom: 40px;
}
.footer-logo {
  flex: 1;
  /* max-height: 300px; */
  text-align: center;
}
.footer-logo img {
  max-height: 200px;
  width: auto;
}

.footer-bottom {
  display: flex;
  flex-direction: row; /* Keep items in a row */
  justify-content: space-between; /* Space between items */
  align-items: center; /* Align items vertically */
  width: 100%; /* Full width */
}

.copy-right, .social-links, .footer-nav {
  display: flex;
  align-items: center; /* Align items vertically */
}

.copy-right {
  flex: 1; /* Allow to grow and shrink as needed */
  justify-content: flex-start; /* Align to the left */
}

.social-links {
  flex: 2; /* Give more room to grow but also can shrink */
  justify-content: right; /* Center content */
  display: flex; /* Ensure icons are flex items */
  gap: 15px; /* Space between icons */
}


.ctabtn {
  padding: 15px 20px; 
  margin: 0 20px;
}


.footer-nav {
  flex: 1; /* Allow to grow and shrink as needed */
  justify-content: flex-end; /* Align to the right */
}

.footer-terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.footer .footer-terms .copy-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .footer-terms .copy-right p,
.footer .footer-terms .copy-right a {
  margin: 10px 0; /* Ensure spacing between elements */
  text-align: center; /* Center-align text for each element */

}

.footer a {
  white-space: nowrap;
}

.footer-terms a {
  margin-right: 20px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .footer-bottom {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: center; /* Center-align items */
    text-align: center;

  }
  .locations {
    grid-template-columns: 1fr;
    gap: 20px;
    grid-row: 1;
  }
  .copy-right, .social-links, .footer-nav {
    width: 100%; /* Full width for smaller screens */
    justify-content: center; /* Center content */
    margin: 5px 0; /* Add some space between them */
  }
  .footer-nav {
    margin-top: 20px;
  }
}

.footer {
  background-color: #131e24; 
  padding: 40px 80px;
}

.footer-content {
  max-width: 1200px; /* Max width of the content */
  margin: 0 auto;
  gap: 20px;
}

.locations {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-row: 1;
}

.footer-bottom {
  display: flex;
  align-items: center;
  margin-top: 40px; /* Adjust as needed */
  border-top: 2px solid #08f784; /* Top border for the bottom footer */
  padding-top: 20px;
  grid-row: 2;
}

.footer-nav a {
  margin-right: 20px;
  white-space: nowrap;
}

.footer-nav, .social-links, .copy-right {
  width: 100%; /* Ensure each child takes full width */
}

/* HERO */
.hero-section select, .hero-section button {
  /* border: 1px solid #384e68; */
  border-radius: 20px;
  /* height: 40px; */
  /* width: 120px; */
  text-align: center;
  cursor: pointer; /* Change cursor on hover */

}
.hero-section button {
  background-image: linear-gradient(to right, #08f784, #4ac9f3); 
  border: none;
}
.hero-section button:hover {
  background-image: none;
  background-color: white; /* Change background color on hover */
  border: 1px solid #01bf63;
}


/* NEW Sections */
/*  */
/* !!!!!!!!!!!!!!!!!!!!!!!!!!! */
.proud-member {
  text-align: center;
  margin-top: 40px;
}

.proud-member h2 {
  margin-bottom: 20px;
}

.member-logos {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.member-logos img {
  max-width: 100px;
  height: auto;
}

.filter-green{
  filter: invert(34%) sepia(46%) saturate(15) hue-rotate(60deg) brightness(79%) contrast(160%);
}