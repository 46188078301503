/* FAQ.css */
.faq-section {
    padding: 20px;
    background-color: #f9fbfd;
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
}

.faq-title {
    font-size: 42px;
    text-align: center;
    margin-bottom: 20px;
}

.faq-items {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); Two columns of equal width */
    gap: 20px; /* Space between the items */
}

.faq-item {
    background-color: #e9f1f9;
    border-radius: 20px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* .faq-item:hover {
    background-color: #d4e4f1;
} */

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #384e68;
}

.faq-toggle {
    font-size: 24px;
    color: #32CD32;
}

.faq-answer {
    margin-top: 10px;
    font-size: 16px;
    color: #384e68;
}
.faq-section a {
    color: #32CD32;
    font-weight: 600;
}
.faq-section a:hover {
    color:#384e68;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .faq-items {
        grid-template-columns: 1fr; /* Single column for smaller screens */
    }
}
.section-break {
    margin-top: 40px;
    border: 1px solid #08f784;
}
.detail-info {
    font-style: italic;
}
.faq-answer button {
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer; /* Change cursor on hover */
    color: #384e68;
    text-transform: uppercase;
}
.faq-answer button:hover {
    background-image: none;
    background-color: white; 
    border: 1px solid #08f784;
    color: #384e68;
}
.faq-answer .button-wrapper {
    display: flex;
    justify-content: center;
}