.hero-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    overflow: visible;
    align-items: flex-start;
    padding-left: 5%;
    padding-top: 20px;
    z-index: 1;
    overflow-x: hidden;
}

.hero-content {
    max-width: 50%;
}

.welcome-text {
    color: #218721;
    margin-bottom: 10px;
}

.main-heading {
    font-size: 48px;
    /* font-weight: bold; */
    margin-bottom: 20px;
}

.state-selection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.state-dropdown {
    padding: 10px;
    margin-right: 10px;
    text-transform: uppercase;
}
.state-dropdown select {
    display: none !important; /*hide original SELECT element: */
}
.state-dropdown option {
    background: #f8f8f8;
    color: #333;
}

.get-card-button {
    padding: 10px 20px;
    background-image: linear-gradient(to right, #08f784, #4ac9f3); 
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 20px;
    border: 1px solid transparent !important;
    color: #384e68;
    align-items: center;
}
.get-card-button:hover {
    background-image: none;
    background-color: white; 
    border: 1px solid #08f784 !important;
    color: #384e68;
}

.hero-benefits {
    display: flex;
    flex-direction: column;
}

.hero-benefit-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.check-icon {
    margin-right: 10px;
    height: 20px;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
}
.green-cloud {
    position: absolute;
    width: 430px;
    height: 550px;
    background: radial-gradient(circle, rgba(3, 219, 150, 0.438), rgba(72, 255, 0, 0));
    filter: blur(20px);
    border-radius: 50%;
    z-index: -1;
    top: 10%;
    left: 85%;
    transform: translate(-50%, -50%);
}