.section.reverse {
  flex-direction: row-reverse;
}

.media-wrapper {
  max-width: 50%;
  margin-right: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.section.reverse .media-wrapper {
  margin-right: 0;
  margin-left: 40px;
}

.media-circle {
  border-radius: 20px;
}

.section-content h2 {
  margin: 0;
  font-size: 1.5em;
}

.section-content p {
  margin: 0.5em 0 0 0;
}

.highlighted {
  background-color: #e0ffe0;
  padding: 20px;
  border-radius: 20px;
}

.section {
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure content is centered */
  margin: 20px auto;
  /* width: 80%;  */
}

.section.highlighted {
  /* width: 90%;  */
}

.section .media-wrapper {
  flex: 1; 
  margin-right: 50px;
  border-radius: 20px;
  height: 300px;
}

.section.reverse .media-wrapper {
  margin-right: 0;
  margin-left: 50px;
}

.section .section-content {
  flex: 2; /* Allows the content area to grow, more space allocated */
  max-width: 50%; /* Maximum width for text content */
}

.section.highlighted .section-content {
  max-width: 60%; /* Increased width for text in highlighted sections */
}